
import {
  alertController,
  IonBackButton,
  IonButton, IonButtons, IonCol,
  IonContent, IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonPage, IonRow,
  IonTitle,
  IonToolbar,
  modalController
} from "@ionic/vue";
import {defineComponent, ref} from "vue";
import {CreateMaterial} from "@/domain/command/material/material.command";
import {uuid4} from "@capacitor/core/dist/esm/util";
import {useMaterialApi} from "@/domain/service/api/material.api";
import {initialState} from "@/domain/store/viewState/view-state.module";
import {useStockApi} from "@/domain/service/api/stock.api";
import {useStore} from "vuex";
import {Material} from "@/domain/model/material/material.model";
import _ from "lodash";

export default defineComponent({
  name: "MaterialCreatePage",
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonItem,
    IonInput,
    IonLabel,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonButtons
  },
  setup() {
    const store = useStore();
    const {createMaterial, fetchById} = useMaterialApi();
    const isSubmitting = ref(false);
    const command = ref<CreateMaterial>({...initialState.createMaterialInList});
    const closeModal = async () => {
      return modalController.dismiss();
    };
    const onRefAlreadyExist = async () => {
      const alert = await alertController
          .create({
            cssClass: 'my-custom-class',
            header: 'Esa referencia ya existe!',
            message: 'Introduzca una referencia que no exista',
            buttons: [
              {
                text: 'Aceptar',
                role: 'confirm',
                cssClass: 'secondary',
                handler: () => {
                  closeModal();
                },
              },
            ],
          });
      await alert.present();

      const {role} = await alert.onDidDismiss();
    }
    const submit = async () => {
      command.value.id = uuid4();

      if (isSubmitting.value)
        return;

      isSubmitting.value = true;

      const allMaterial = store.getters.allMaterial;
      const allMaterialRefs = allMaterial.map((materialNode: Material) => materialNode.ref.toLowerCase());
      if (_.includes(allMaterialRefs, command.value.ref.toLowerCase())) {
        await onRefAlreadyExist();
        await closeModal();
        return
      }
      await createMaterial(command.value);
      try {
        await fetchById(command.value.id);
        await useStockApi().fetchAll();
      } finally {
        isSubmitting.value = false;
        await closeModal();
      }
    };


    return {command, isSubmitting, submit, closeModal};
  }
})
