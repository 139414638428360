

import {computed, defineComponent, h, ref, watch} from "vue";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCol,
  IonContent,
  IonPage,
  IonRow,
  IonText,
  IonList,
  IonSearchbar,
  IonButton,
  modalController,
  isPlatform,
  IonGrid,
  IonItemDivider, onIonViewWillEnter
} from "@ionic/vue";
import MaterialListXlsImporter from "@/app/view/material/list/MaterialListXlsImporterComponent.vue";
import MaterialListItemComponent from "@/app/view/material/list/MaterialListItemComponent.vue";
import {useStore} from "vuex";
import {Material} from "@/domain/model/material/material.model";
import _, {map} from "lodash";
import MaterialCreatePage from "@/app/view/material/create/MaterialCreatePage.vue";
import {useMaterialApi} from "@/domain/service/api/material.api";
import {useStockApi} from "@/domain/service/api/stock.api";
import {useConfig} from "@/domain/service/kernel/config";
import {NButton, NDataTable, NSpace, NTag} from "naive-ui";
import {useRouter} from "vue-router";
import {Stock} from "@/domain/model/stock/stock.model";
import {useStock} from "@/domain/service/model/stock.service";
import {Order} from "@/domain/model/order/order.model";

export default defineComponent({
  name: "MaterialListPage",
  components: {
    NDataTable,
    NSpace,
    MaterialListItemComponent,
    MaterialListXlsImporter,
    IonPage,
    IonList,
    IonContent,
    IonCard,
    IonCardContent,
    IonSearchbar,
    IonGrid,
    IonRow,
    IonCol,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonText,
    IonButton,
    IonItemDivider,
  },
  setup() {
    const store = useStore();
    const {calculateAvailableStock} = useStock();

    const isTablet = computed<boolean>(() => !isPlatform('desktop'));
    const router = useRouter();
    const data = ref<any>([]);
    const allActivateOrders = computed<Order[]>(() => store.getters.allActiveOrders);

    const {fetchAll} = useMaterialApi();
    const searchWord = ref('');
    const searchMaterial = (material: Material, word: string): boolean => material.ref.toLowerCase().includes(word.toLowerCase()) ||
        material.name.toLowerCase().includes(word.toLowerCase())
        || material.group.toLowerCase().includes(word.toLowerCase());
    const filterMaterial = (materials: Material[], word: string): Material[] => _.filter(materials, (material: Material) => searchMaterial(material, word));
    const sortMaterial = (materials: Material[]): Material[] => _.sortBy(materials, (material: Material) => material.ref.toLowerCase());
    const justTypeMaterial = (materials: Material[]): Material[] => _.filter(materials, (material: Material) => material.type === 'part');
    // const allMaterials = computed(() => store.getters.allMaterial);
    const materials = computed(() => sortMaterial(filterMaterial(justTypeMaterial(store.getters.allMaterial), searchWord.value)));
    const isEmpty = computed(() => {
      return store.getters.allMaterial.length === 0;
    });


    const replaceData = () => {
      data.value =
          map(materials.value, (material: Material) => {
            return {
              // material: material,
              id: material.id,
              ref: material.ref,
              description: material.name,
            };
          });
    };


    const calculateStock = (ref: string): boolean => {
      const stockAvailable = calculateAvailableStock(allActivateOrders.value, ref);
      return stockAvailable >= 0;
    }


    const goMaterialDetails = (id: string) => {
      router.push({
        name: 'MaterialDetail',
        params: {id: id}
      });
    };

    // fetchAll();

    const onAddMaterial = async () => {
      const modal = await modalController
          .create({
            component: MaterialCreatePage
          });
      return modal.present();
    };

    const columns = [
      {
        title: "Referencia",
        key: "ref",
        sorter: 'default'
      },
      {
        title: "Descripción",
        key: "description",
        sorter: 'default'
      },
      {
        title: "Estado",
        key: "state",
        sorter: (row1: any, row2: any) => {
          if(Number(calculateStock(row1.ref)) > Number(calculateStock(row2.ref)))
            return 1;
          if(Number(calculateStock(row1.ref)) < Number(calculateStock(row2.ref)))
            return -1;
          else
            return 0;
        },
        render(row: any): any {
          return h(
              NTag,
              {
                type: calculateStock(row.ref) ? 'success' : 'error',
              },
              {default: () => 'Stock'},
          );
        },
      },
      {
        title: 'Acciones',
        key: 'actions',
        render(row: any): any {
          return h(
              NButton,
              {
                size: 'small',
                onClick: () => goMaterialDetails(row.id),
              },
              {default: () => 'Detalles'},
          );
        },

      },
    ];


    watch(materials, () => replaceData(), {immediate: true});

    onIonViewWillEnter(async () => {
      await fetchAll();
      await useStockApi().fetchAll();
    });
    // onIonViewWillLeave(async () => {
    //   await fetchAll();
    //   await useStockApi().fetchAll();
    // });

    return {
      columns,
      searchWord,
      materials,
      isEmpty,
      onAddMaterial,
      isTablet,
      isProd: useConfig().isProduction(),
      data,
    };
  }
})
