

import {
  IonBadge,
  IonButton,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  isPlatform,
  onIonViewWillEnter
} from "@ionic/vue";
import {computed, defineComponent, ref} from "vue";
import {useRouter} from "vue-router";
import {useStore} from "vuex";
import {Material} from "@/domain/model/material/material.model";
import {Stock} from "@/domain/model/stock/stock.model";
import {useStock} from "@/domain/service/model/stock.service";
import {Order} from "@/domain/model/order/order.model";
import {useMaterialApi} from "@/domain/service/api/material.api";

export default defineComponent({
  name: "MaterialListComponent",
  components: {
    IonGrid,
    IonItem,
    IonLabel,
    IonCol,
    IonRow,
    IonBadge,
    IonButton,
  },
  props: {
    material: null
  },
  setup(prop: any) {
    const router = useRouter();
    const store = useStore();
    const isTablet = computed<boolean>(() => !isPlatform('desktop'));
    const {calculateBlockStock, calculateAvailableStock} = useStock();
    const materialRef = ref<Material>(prop.material);
    const id = materialRef.value.id;
    const allActivateOrders = computed<Order[]>(() => store.getters.allActiveOrders);
    const stockInFactory = computed<Stock>(() => store.getters.lastStockByRef(materialRef.value.ref));
    const stockAvailable = computed<number>(() => calculateAvailableStock(allActivateOrders.value, materialRef.value.ref));
    const stockReserved = computed<number>(() => calculateBlockStock(allActivateOrders.value, materialRef.value.ref));
    const actualStatus = computed<boolean>(() => stockAvailable.value >= 0);

    const goMaterialDetails = () => {
      router.push({
        name: 'MaterialDetail',
        params: {id: materialRef.value.id}
      });
    };

    return {
      actualStatus,
      materialRef,
      router,
      id,
      stockAvailable,
      stockReserved,
      stockInFactory,
      goMaterialDetails,
      isTablet,
    };
  }
})
